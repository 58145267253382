import React from "react";

function JobCard({ title, location, jobId, link, company }) {
  return (
    <div style={styles.card}>
      <h3>
        {title} @ 
        <span style={styles.company}>{company}</span> {/* Styled company */}
      </h3>
      <p><strong>Location:</strong> {location}</p>
      <p><strong>Job ID:</strong> {jobId}</p>
      <a href={link} target="_blank" rel="noopener noreferrer" style={styles.applyButton}>
        Apply
      </a>
    </div>
  );
}

const styles = {
  card: {
    border: "1px solid #ddd",
    padding: "16px",
    margin: "8px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  company: {
   // Black background
    color: "#FF5A5F", // Red te// Padding inside the box
    borderRadius: "4px", // Rounded corners
    marginLeft: "4px", // Slight space after the "@" symbol
    display: "inline-block", // Ensures proper box alignment
  },
  applyButton: {
    display: "inline-block",
    padding: "8px 16px",
    color: "#fff",
    backgroundColor: "black",
    textDecoration: "none",
    borderRadius: "4px",
    fontWeight: "bold", // Makes the text thick
  },
};

export default JobCard;
