// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar";
import JobListPage from "./pages/JobListPage";
import AboutUs from "./pages/AboutUs";



function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<JobListPage />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
