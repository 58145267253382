import React, { useState } from 'react';
import coFounder from "../images/co-founder.png";
import { FaLinkedin, FaEnvelope } from "react-icons/fa"; // Importing icons from react-icons

const AboutUs = () => {
  const [hovered, setHovered] = useState(false); // State to track hover

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>About Us</h1>
      <p style={styles.description}>
        We are a dedicated team of two software & data professionals.<br></br>
        Our mission is to serve job seekers with only the most recent job postings.
      </p>
      <img 
        src={coFounder}
        alt="Co-founders" 
        style={styles.image} 
      />
      <div style={styles.contactContainer}>
        <div style={styles.contactColumn}>
          <h3 style={styles.contactName}>Saurabh Rai</h3>
          <div style={styles.iconRow}>
            <a 
              href="https://linkedin.com/in/saurabhmanojrai/" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.iconLink}
            >
              <FaLinkedin size={24} />
            </a>
            <div 
              style={styles.gmailIcon}
              onMouseEnter={() => setHovered(true)} // Set hover state to true
              onMouseLeave={() => setHovered(false)} // Set hover state to false
            >
              <FaEnvelope size={24} style={{ color: '#FF5A5F' }} />
              <span 
                style={{
                  ...styles.emailAddress,
                  visibility: hovered ? 'visible' : 'hidden', // Conditionally render the email
                  opacity: hovered ? 1 : 0, // Conditionally change opacity
                }}
              >
                raisaurabh195@gmail.com
              </span>
            </div>
          </div>
        </div>
        <div style={styles.contactColumn}>
          <h3 style={styles.contactName}>Pradyuman Gangan</h3>
          <div style={styles.iconRow}>
            <a 
              href="https://www.linkedin.com/in/pradyumangangan/" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.iconLink}
            >
              <FaLinkedin size={24} />
            </a>
            <div 
              style={styles.gmailIcon}
              onMouseEnter={() => setHovered(true)} // Set hover state to true
              onMouseLeave={() => setHovered(false)} // Set hover state to false
            >
              <FaEnvelope size={24} style={{ color: '#FF5A5F' }} />
              <span 
                style={{
                  ...styles.emailAddress,
                  visibility: hovered ? 'visible' : 'hidden', // Conditionally render the email
                  opacity: hovered ? 1 : 0, // Conditionally change opacity
                }}
              >
                pradyuman.gangan02@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2.5em',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '20px',
  },
  description: {
    fontSize: '1.2em',
    lineHeight: '1.6',
    marginBottom: '30px',
  },
  image: {
    maxWidth: '100%',
    height: '400px',
    borderRadius: '10px',
    marginTop: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    margin: '20px auto',
  },
  contactColumn: {
    width: '45%',
    textAlign: 'center',
  },
  contactName: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#000',
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px', // Spacing between icons
    marginTop: '10px',
  },
  iconLink: {
    textDecoration: 'none',
  },
  gmailIcon: {
    position: 'relative',
    display: 'inline-block', // To allow hover effect
  },
  emailAddress: {
    position: 'absolute',
    top: '30px', // Adjust position to appear below the icon
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#FF5A5F', // Matches the Gmail red
    fontSize: '1.2em',
    fontWeight: 'bold',
    transition: 'visibility 0.2s ease, opacity 0.2s ease',
  },
};

export default AboutUs;
